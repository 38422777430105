import { useEffect, useMemo } from "react";
import { IMAGES } from "../assets/images";
import { useReviewsQuery } from "../hooks/query/useReviews.query";

const Testimonials = () => {
  const { data, isLoading } = useReviewsQuery();

  const reviews: TReviews[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  useEffect(() => {
    setTimeout(() => {
      new Swiper(".testimonials-swiper", {
        slidesPerView: 1,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
        autoplay: {
          delay: 3000,
        },
        spaceBetween: 0,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        loop: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }, 2000);
  }, []);

  return (
    <section className="testimonials">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="testimonials__main">
              <div className="block-text center">
                <h6 className="sub-heading">
                  <span>Reviews</span>
                </h6>
                <h3 className="heading wow" data-splitting>
                  What People Say
                </h3>
              </div>

              <div className="swiper testimonials-swiper pb-5">
                <div className="swiper-wrapper">
                  {reviews.length > 0 &&
                    reviews?.map((review) => (
                      <div className="swiper-slide" key={review._id}>
                        <div className="box-testimonial center">
                          <img
                            src={IMAGES.quote}
                            alt=""
                            className="quoteImage"
                          />
                          <p className="text">"{review.title}"</p>
                          <p className="text-message mt-24">{review.review}</p>
                          <div className="info">
                            <div className="user_avatar">
                              <p>{review.name[0]}</p>
                            </div>
                            <h5 className="name">{review.name}</h5>
                            <p>
                              {review.verifiedBlueTick
                                ? "Verified User"
                                : "Ordinary User"}
                            </p>
                            <div className="d-flex justify-content-center">
                              {Array.from(
                                { length: review.rating },
                                (_, index) => (
                                  <img
                                    key={index}
                                    src={IMAGES.starRating}
                                    className="image-fluid rating_star"
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-next">
                  <svg
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.8788 7.46543L2.43654 13.8092C2.1795 14.0662 1.76387 14.0662 1.50683 13.8092L1.11856 13.4209C0.861521 13.1639 0.861521 12.7482 1.11856 12.4912L6.7131 7.00059L1.12402 1.50997C0.866998 1.25293 0.866998 0.837303 1.12402 0.580281L1.51231 0.191991C1.76933 -0.0650311 2.18496 -0.0650311 2.442 0.191991L8.88422 6.53574C9.1357 6.79278 9.1357 7.20841 8.8788 7.46543Z"
                      fill="white"
                      fill-opacity="0.5"
                    />
                  </svg>
                </div>
                <div className="swiper-button-prev">
                  <svg
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.1212 7.46543L7.56346 13.8092C7.8205 14.0662 8.23613 14.0662 8.49317 13.8092L8.88144 13.4209C9.13848 13.1639 9.13848 12.7482 8.88144 12.4912L3.2869 7.00059L8.87598 1.50997C9.133 1.25293 9.133 0.837303 8.87598 0.580281L8.48769 0.191991C8.23067 -0.0650311 7.81504 -0.0650311 7.558 0.191991L1.11578 6.53574C0.864303 6.79278 0.864302 7.20841 1.1212 7.46543Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
